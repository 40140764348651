import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import {
  peopleCircleOutline,
  book,
  bookmarkOutline,
  browsersOutline,
  folderOpenOutline,
  nutritionSharp,
} from "ionicons/icons";
const Home: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="tabcolor">
          <IonRow>
            <IonCol>
              <IonRow>
                <img
                  src="assets/icon/logowhite.png"
                  alt="ion"
                  className="imgcontainer"
                ></img>

                <IonText className="imgcontainer">
                  SPCMC SERVICES <br></br>
                </IonText>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid  className="maingrid">
          <IonRow className="holdermid">
            <IonCard href="https://book.spcmc.online">
              <img
                src="assets/icon/booking.png"
                alt="ion"     
                className="imgcontainer2"          
              ></img>
              <IonCardHeader>
                <IonCardTitle>
                  <IonIcon icon={book} slot="start" />
                  <IonLabel> Online Booking (Book)</IonLabel>
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>Book a Covid / Procedure</IonCardContent>
            </IonCard>

            <IonCard href="https://vouchers.spcmc.online">
              <img
                src="assets/icon/vouchers.png"
                alt="ion"     
                className="imgcontainer2"           
              ></img>
              <IonCardHeader>
                <IonCardTitle>
                  <IonIcon icon={book} slot="start" />
                  <IonLabel> Voucher (Vouchers)</IonLabel>
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>Claim Voucher Here</IonCardContent>
            </IonCard>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Home;
